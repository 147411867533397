import {useRouter} from "next/router";
import React from "react";

/**
 *
 * @param theme
 * @returns {JSX.Element}
 * @constructor
 */
export default function Back ({ theme } : { theme : string }) {
    // init
    const router = useRouter();

    /**
     *
     * @param event
     */
    function goBack(event : any) {
        event.preventDefault();
        router.back();
        event.target.blur();
    }

    // render
    return (
        <>
            <button onClick={goBack} id={'backButton'} className={"border-0 d-none me-md-2 btn btn-outline-secondary p-1 ps-2 pe-2" + ('dark' === theme ? ' bg-dark text-secondary' : '')} type="button" aria-label="Back">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     className="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg>
            </button>
        </>
    )
}