/**
 *
 */
class Util {
    /**
     *
     */
    constructor() {

    }

    /**
     *
     * @param locale
     */
    determineLanguageFromLocale(locale : string) : string {
        return locale.substr(0, 2)
    }

    /**
     *
     * @param locale
     */
    determineCountryFromLocale(locale : string) : string {
        return locale.substr(3, 2)
    }

    /**
     *
     * @returns {string}
     */
    randomString() : string {
        return (Math.random() + 1).toString(36).substring(7)
    }

    /**
     *
     * @param value
     * @returns {string}
     */
    capitalizeFirstLetter(value : string) : string {
        return value.charAt(0).toUpperCase() + value.slice(1);
        /*const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) =>
            first.toLocaleUpperCase(locale) + rest.join('')*/
    }

    /**
     *
     * @param locale
     * @param path
     */
    createURL(
        locale : string,
        path : string
    ) : string {
        path = '/' === path.charAt(0) ? path : ('/' + path);
        return process.env.url + '/' + locale + path;
    }

    /**
     *
     * @param ms
     */
    sleep(ms : number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    /**
     *
     * @param items
     */
    createName(items : {url:string, name:string}[]) : string {
        let name = '';

        items.map((item) => {
            name += item.name + ' ';
        });

        return name.substr(0, name.length - 1);
    }
}

export default Util;