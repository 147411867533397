import Link from "next/link";
import Img, {ImageData} from "../image";

/**
 *
 */
export type LinkData = {
    active : boolean
    theme : string
    url : string
    locale : string
    name : string
    image ?: ImageData|null
    svg ?: string|null
};

/**
 *
 * @param active
 * @param theme
 * @param url
 * @param locale
 * @param name
 * @param image
 * @param svg
 * @constructor
 */
export default function HeaderLink ({ active, theme, url, locale, name, image, svg } : LinkData) {
    // render
    return (
        <>
            {
                locale ?
                <>
                <Link href={url} locale={locale} className={"ms-3 ms-sm-1 pb-2 d-inline-block nav-link" + ('dark' === theme ? ' hover-link-secondary' + (active ? ' text-light' : ' text-secondary') : (' hover-link-primary' + active ? ' text-primary' : ''))}>
                    {
                        image ?
                            <Img
                                url={image.url}
                                alt={image.alt}
                                width={image.width ?? 16}
                                height={16}
                                priority={false}
                            />
                            : ''
                    }
                    {
                        svg ? <span className={'align-text-bottom'} dangerouslySetInnerHTML={{ __html : svg }} /> : ''
                    }
                    <span className={'align-text-bottom' + ((image || svg) ? ' ms-2' : '')}>{name}</span>
                </Link>
                <br />
                </>
                :
                <>
                <Link href={url} className={"ms-3 ms-sm-1 pb-2 d-inline-block nav-link" + ('dark' === theme ? ' hover-link-secondary' + (active ? ' text-light' : ' text-secondary') : ' hover-link-primary' + (active ? ' text-primary' : ''))}>
                    {
                        image ?
                            <Img
                                url={image.url}
                                alt={image.alt}
                                width={18}
                                height={18}
                                priority={false}
                            />
                            : ''
                    }
                    {
                        svg ? <span className={'align-text-bottom'} dangerouslySetInnerHTML={{ __html : svg }} /> : ''
                    }
                    <span className={'align-text-bottom' + ((image || svg) ? ' ms-2' : '')}>{name}</span>
                </Link>
                <br />
                </>
            }
        </>
    );
}