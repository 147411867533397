import {useEffect, useState} from "react";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Install () {
    // init variables
    const [visible, setVisible] = useState(false);

    // init the button
    useEffect(
        function () {
            // initialize the install app button
            window.addEventListener('beforeinstallprompt', (event) => {
                // Prevent the mini-infobar from appearing on mobile
                event.preventDefault();

                // Stash the event so it can be triggered later.
                (window as any).deferredPrompt = event;

                // show the install-button
                setVisible(true);
            });

            // track that the app is installed
            window.addEventListener('appinstalled', (event) => {
                // Clear the deferredPrompt so it can be garbage collected
                (window as any).deferredPrompt = null;
            });
        }
    );

    /**
     *
     * @param event
     */
    async function install(event : any) {
        // if the deferred prompt isn't available, do nothing
        const promptEvent = (window as any).deferredPrompt;
        if (! promptEvent) {
            return;
        }

        // Show the install prompt.
        promptEvent.prompt();

        // Log the result
        const result = await promptEvent.userChoice;

        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        (window as any).deferredPrompt = null;

        // Hide the install button.
        setVisible(false);
    }

    // render
    return (
        <>
            <button id={'install'} className={(visible ? '' : 'visually-hidden ') + "btn btn-outline-secondary mt-3 mb-3 mt-md-0 mb-md-0 ms-md-3"} type="button" onClick={install}>
                Install App
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-file-arrow-down ms-1" viewBox="0 0 16 16">
                    <path
                        d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5z"/>
                    <path
                        d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                </svg>
            </button>
        </>
    )
}