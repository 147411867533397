import Link from "next/link";

/**
 *
 */
export type ElementData = {
    type : string
    url : string
    name : string
};

/**
 *
 * @param type
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
export default function Element ({ type, url, name } : ElementData) {
    // render
    return (
        <>
            <div className={'nav-link' + ('dark' === type ? ' hover-link-secondary' : ' hover-link-primary')}>
                <Link href={url} className={"text-decoration-none" + ('dark' === type ? ' text-secondary' : '')}>
                    {name}
                </Link>
            </div>
        </>
    );
}