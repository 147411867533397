import Element, {ElementData} from "./element";
import {useTranslation} from "next-i18next";

/**
 *
 */
export type HeaderData = {
    type : string
    name : string
    elements : ElementData[]
};

/**
 *
 * @param type
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header ({ type, name, elements } : HeaderData) {
    // init
    const { t } = useTranslation();

    // render
    return (
        <>
            <div className="col-md-3 col-lg-2 text-start mt-3 mb-3 text-center text-md-start">
                <p className={'h6 ' + ('dark' === type ? 'text-secondary' : '')}>{t(name)}</p>
                {elements.map((element, index) =>
                    <Element key={index} type={type} url={element.url} name={t(element.name)} />
                )}
            </div>
        </>
    );
}