import BackButton from "./back";
import Logo from "./logo";
import Toggler from "./toggler";
import Install from "./install";
import Locales from "./locales";
//import Countries from "./countries";
import Header, {HeaderData} from "./header";
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";

/**
 *
 * @param theme
 * @param appName
 * @param language
 * @param country
 * @param environment
 * @param headers
 * @constructor
 */
export default function Navigation ({ theme, appName, language, country, environment, headers } : { theme : string, appName : string, language : string, country : string, environment : string, headers : HeaderData[] }) {
    // init
    const { t } = useTranslation();
    const router = useRouter();

    // check active-state
    headers.map(
        (header, index) => {
            header.elements.map(
                (element, key) => {
                   if(element.url === router.pathname) {
                       headers[index].elements[key].active = true;
                   } else {
                       headers[index].elements[key].active = false;
                   }
                }
            )
        }
    );

    // render
    return (
        <>
            <nav id="header" className={"navbar navbar-expand-md" + ('dark' === theme ? ' navbar-dark bg-dark' : ' navbar-light bg-light')}>
                <div id={'navigationContent'} className="container-fluid justify-content-between align-items-center flex-row-reverse flex-md-row">
                    <BackButton theme={theme} />
                    <Logo name={appName} environment={environment} />
                    <Toggler />
                    {/*ms-5 ms-md-0 ps-5 ps-md-0*/}
                    <div className="collapse navbar-collapse text-center mt-3 mt-md-0" id="navHeader">
                        <div className="navbar-nav">
                            <Locales theme={theme} country={country} />
                            {/*<Countries theme={theme} language={language} />*/}
                            {
                                // @ts-ignore
                                headers.map((header, index) =>
                                    <Header key={index} theme={theme} name={t(header.name)} elements={header.elements} />
                                )
                            }
                        </div>
                        <Install />
                    </div>
                </div>
            </nav>
        </>
    )
}