import Header, {HeaderData} from "./header";
import { useTranslation } from 'next-i18next';
import SocialMedia from "../socialMedia";

/**
 *
 */
type Data = {
    type : string
    headers : HeaderData[]
};

/**
 *
 * @param type
 * @param language
 * @param headers
 * @returns {JSX.Element}
 * @constructor
 */
export default function Navigation ({ type, headers } : Data) {
    // init
    const { t } = useTranslation();

    // render
    return (
        <>
        <footer id="footer" className={"container-fluid p-4" + ('dark' === type ? ' bg-dark' : '')}>

            {/* Header-Links */}
            <nav className="row nav small justify-content-md-around">
                {headers.map((header, index) =>
                    <Header key={index} type={type} name={header.name} elements={header.elements} />
                )}
            </nav>

            {/* Social Media */}
            <div className={"col-12 text-center small mt-4 mb-3" + ('dark' === type ? ' text-secondary' : '')}>
                <SocialMedia type={type} />
            </div>

            {/* Recaptcha */}
            {/*<div
                className={"col-12 text-center small mt-4 mb-5 ps-md-3 pe-md-3 ps-lg-5 pe-lg-5" + ('dark' === type ? ' text-secondary' : '')}
                dangerouslySetInnerHTML={{__html: t('recaptcha')}}
            />*/}

            {/* Copyright */}
            <div className={"col-12 text-center small mt-4 mb-3" + ('dark' === type ? ' text-secondary' : '')}>
                <span className={'small'}>Copyright © {new Date().getFullYear() + ' ' + process.env.appName}. {t('rights')}.</span>
            </div>
        </footer>
        </>
    )
}