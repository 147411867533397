import Link from "next/link";
import Img from "../image";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Logo ({name, environment } : { name : string, environment : string }) {
    // init
    const urlPrefix = name.toLowerCase();

    // render
    return (
        <>
            {/* Mobile */}
            <Link href="/" className="d-md-none navbar-brand d-flex nav-link p-0 m-0 position-absolute start-50 top-0 mt-2 translate-middle-x">
                <Img url={urlPrefix + '-logo.svg'} alt={name + ' logo'} width={38} height={38} priority={true} />
            </Link>
            {/* Tablet and Desktop */}
            <Link href="/" className="navbar-brand d-none d-md-flex nav-link p-0 m-0 align-items-center me-3">
                <Img url={urlPrefix + '-logo.svg'} alt={name + ' logo'} width={30} height={30} priority={true} />
                <span className="m-0 ms-2">{name} <b className={'text-secondary'}>{('Production' !== environment ? environment : 'B E T A')}</b></span>
            </Link>
        </>
    )
}