import HeaderLink, {LinkData} from "./link";
import {useTranslation} from "next-i18next";
//import Img from "../image";

/**
 *
 */
export type HeaderData = {
    theme : string
    name : string
    elements : LinkData[]
};

/**
 *
 * @param theme
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header ({ theme, name, elements } : HeaderData) {
    // init
    const id = "dropdown-menu-" + name.toLowerCase();
    const { t } = useTranslation();
    let activeElement : LinkData = elements[0];
    elements.map((element) => {
        if(element.active) {
            activeElement = element;
        }
    });

    // render
    return (
        <>
            <div>
                <div className="dropdown d-inline-block">
                    <button type="button" className={"btn dropdown-toggle headerToggle" + ('dark' === theme ? ' link-secondary' : ' link-primary')} id={id}
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {
                            /*activeElement && activeElement.image
                                ?
                                <>
                                    <div className={'align-text-top d-inline'}>
                                    <Img
                                        url={activeElement.image.url}
                                        alt={activeElement.image.alt}
                                        width={16}
                                        height={16}
                                        priority={false}
                                    />
                                    </div>
                                    <span className={'mb-5 ms-2'}>{name}</span>
                                </>
                                : activeElement && activeElement.svg
                                    ?
                                    <>
                                        <span dangerouslySetInnerHTML={{ __html : activeElement.svg }} />
                                        <span className={'mb-5 ms-2'}>{name}</span>
                                    </>
                                    :*/
                                    <span>{name}</span>
                        }
                    </button>
                    <div className={"dropdown-menu pt-0 pt-md-2 ps-4 ps-md-2 border-0" + ('dark' === theme ? ' bg-dark' : '')} aria-labelledby={id}>
                    {elements.map((element, index) =>
                        <HeaderLink
                            active={element.active ?? false}
                            key={name + index}
                            theme={theme}
                            url={element.url}
                            locale={element.locale ?? null}
                            name={t(element.name)}
                            image={element.image ?? null}
                            svg={element.svg ?? null}
                        />
                    )}
                    </div>
                </div>
            </div>
        </>
    );
}