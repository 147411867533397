import Image, {ImageLoaderProps} from 'next/image';

/**
 *
 * @param src
 * @param width
 * @param quality
 * @returns {*}
 */
const customLoader = ({ src, width, quality } : ImageLoaderProps) => {
    if(-1 !== src.search(/^https:\/\/www.openfoodfacts.org\/images\/products/)) {
        // open food facts
        return src;
    } else if(-1 !== src.search(/^https:\/\/eqno-product.imgix.net\/product/)) {
        // google cloud storage
        return src + '?auto=format&fit=max&w=' + width;
    } else {
        // default is imgix
        return 'https://eqno.imgix.net/' + src + '?auto=format&fit=max&w=' + width;
    }
}

/**
 *
 */
export type ImageData = {
    url : string
    alt : string
    width : number
    height : number
    priority : boolean
    figure ?: boolean
};

/**
 *
 * @param url
 * @param alt
 * @param width
 * @param height
 * @param priority
 * @param figure
 * @constructor
 */
export default function Img ({ url, alt, width, height, priority, figure = false } : ImageData) {
    let cssClass = "align-baseline mw-100 h-auto ";
    cssClass += figure ? 'figure-img img-fluid rounded' : 'rounded';

    if(-1 !== url.search(/^https:\/\/www.openfoodfacts.org/)) {
        return (
            <Image
                src={url}
                alt={alt}
                width={width}
                height={height}
                priority={priority} // @ts-ignore
                //loader={customLoader}
                unoptimized={true}
                className={cssClass}
            />
        )
    } else {
        return (
            <Image
                src={url}
                alt={alt}
                width={width}
                height={height}
                priority={priority}
                loader={customLoader}
                className={cssClass}
            />
        )
    }
}