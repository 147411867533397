import Head from 'next/head';
import {useRouter} from "next/router";
import React from "react";
import Page from "../lib/page";

/**
 *
 */
export enum Type {
    Article = 'article',
    Website = 'website'
}

/**
 *
 */
type Data = {
    type : Type
    title : string
    description : string
    keywords : string[]
    imageURL : string
    urlOnlyForThisCountry : boolean
    canonical : boolean
};

/**
 *
 * @param type
 * @param title
 * @param description
 * @param keywords
 * @param imageURL
 * @param urlOnlyForThisCountry
 * @param canonical
 * @constructor
 */
export default function MetaData ({ type, title, description, keywords, imageURL, urlOnlyForThisCountry = false, canonical = false } : Data) {
    // init
    const router = useRouter();
    const page = new Page;
    // @ts-ignore
    const locale = router.locale ?? process.env.locales.default;
    page.init(locale);
    // @ts-ignore if the url must not be canonical, but the locale is the default one, select the canonical url without locale
    canonical = canonical ? canonical : (router.locale === process.env.locales.default);

    // add the logo name to the keywords
    const appName = process ? process.env ? (process.env.appName ?? '') : '' : '';
    keywords.push(appName);

    // create the canonical in regard to if we need the country in the url or not
    // @ts-ignore
    const defaultURL = process.env.url + (locale === process.env.locales.default ? '' : '/' + locale) + router.asPath
    const url = process.env.url + ('/' + locale) + router.asPath
    const pageTitle = `${title} | ${appName}`;

    // create the alternative-links for this url
    let alternatives = []
    if(urlOnlyForThisCountry) {
        // @ts-ignore
        for(let l=0; l<process.env.languages.values.length; l++) {
            // create the alternatives for the languages and this country
            // @ts-ignore
            const locale = process.env.languages.values[l] + '-' + page.country
            alternatives.push(
                {
                    'locale': locale,
                    'url': process.env.url + '/' + locale + router.asPath
                }
            )

            // add the single language as a locale as well
            alternatives.push(
                {// @ts-ignore
                    'locale': process.env.languages.values[l],
                    'url': process.env.url + '/' + locale + router.asPath
                }
            )
        }
    } else {
        // @ts-ignore
        for(let l=0; l<process.env.languages.values.length; l++) {
            // create the alternatives for the languages and all countries
            // @ts-ignore
            for (let c=0; c<process.env.countries.values.length; c++) {
                // @ts-ignore
                const locale = process.env.languages.values[l] + '-' + process.env.countries.values[c]
                // add the single language as a locale as well
                // @ts-ignore
                alternatives.push(
                    {// @ts-ignore
                        'locale': process.env.languages.values[l],
                        'url': process.env.url + '/' + locale + router.asPath
                    }
                )

                alternatives.push(
                    {
                        'locale': locale,
                        'url': process.env.url + '/' + locale + router.asPath
                    }
                )
            }
        }
    }

    // render
    return (
        <>
            <Head>
                {/* Canonical-URL */}
                {canonical ? <link key={'canonical'} rel='canonical' href={defaultURL} /> : ''}

                {/* Alternate links */}
                <link key={'x-default'} rel="alternate" hrefLang='x-default' href={process.env.url + router.asPath} />
                {alternatives.map((alternative) =>
                    <link key={'hrefLang_' + alternative.locale} rel="alternate" hrefLang={alternative.locale} href={alternative.url} />
                )}

                {/* General */}
                <title>{pageTitle}</title>
                <meta name='description' content={description}/>
                <meta name='keywords' content={keywords.toString()}/>

                {/* Open Graph (Facebook, Twitter, LinkedIn) specific */}
                <meta property='og:url' content={urlOnlyForThisCountry ? url : defaultURL} />
                <meta property='og:type' content={type} />
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='og:image' content={imageURL} />
                <meta property='og:image:alt' content={description} />
                <meta property='og:locale' content={locale} />
                {alternatives.map((alternative) =>
                    locale === alternative.locale ? '' : <meta key={'language-alternate-' + alternative.locale} property='og:locale:alternate' content={alternative.locale} />
                )}
            </Head>
        </>
    );
}