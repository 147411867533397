import FooterNavigation from "./footer/navigation";


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Footer () {
    // render
    return (
        <>
            <FooterNavigation type={'dark'} headers={process.env.footer} />
        </>
    );
}