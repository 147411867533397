/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Toggler () {
    return (
        <>
            <button id="headerToggler" className="border-0 navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navHeader" aria-controls="navHeader" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
            </button>
        </>
    )
}