import {useRouter} from "next/router";
import Header from "./header";
import { useTranslation } from 'next-i18next';

/**
 *
 * @param theme
 * @param country
 * @returns {JSX.Element}
 * @constructor
 */
export default function Locales ({ theme, country } : { theme : string, country : string }) {
    // init
    const router = useRouter();
    const { t } = useTranslation();
    const currentLanguage = router.locale ? router.locale.substring(0, 2).toLowerCase() : (router.defaultLocale ? router.defaultLocale.substring(0, 2).toLowerCase() : '');

    // init locales
    let elements = [];
    // @ts-ignore
    const languageValues = process.env.languages.values ?? [];
    for(let index in languageValues) {
        elements.push({
            'active': currentLanguage === languageValues[index],
            'name': t(languageValues[index], { ns: 'languages' }),
            'url':  router.asPath,
            'locale': languageValues[index] + '-' + country,
            'image': {
                'url': '/flags/' + languageValues[index].toLowerCase() + '.svg',
                'alt': t('flag.from') + ' ' + t(languageValues[index], { ns: 'countries' }),
                'width': 16
            }
        });
    }

    // render
    return (
        <>
            {/*<div id="locales">*/}
            {/*    /!* @ts-ignore *!/*/}
            {/*    <Header theme={theme} name={t('language')} elements={elements} />*/}
            {/*</div>*/}
            {/* @ts-ignore */}
            <Header theme={theme} name={t('language')} elements={elements} />
        </>
    );
}