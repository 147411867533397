import HeaderNavigation from "./header/navigation";
import React from "react";
import {useRouter} from "next/router";
import Page from "../lib/page";


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header () {
    // init
    const router = useRouter();
    const page = new Page; // @ts-ignore
    const locale = router.locale ?? process.env.locales.default;
    page.init(locale);

    // render
    return (
        <>
            <HeaderNavigation
                theme={'dark'}// @ts-ignore
                appName={process.env.appName}
                language={page.language}
                country={page.country}// @ts-ignore
                environment={process.env.environment} // @ts-ignore
                headers={process.env.header}
            />
        </>
    );
}