import Util from "./util";

/**
 * Page helper class
 */
class Page {
    /**
     *
     */
    constructor() {
        this.util = new Util();

        this.language = '';
        this.country = '';
        this.locale = '';
    }

    /**
     *
     */
    init(locale) {
        this.language = this.util.determineLanguageFromLocale(locale);

        this.country = this.util.determineCountryFromLocale(locale);
        if('' === this.country) {
            this.country = process.env.countries.default;
        }

        this.locale = this.language + '-' + this.country;
    }
}

export default Page;