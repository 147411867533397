import Instagram from "./social/instagram";
//import Facebook from "./social/facebook";
//import Twitter from "./social/twitter";
//import Youtube from "./social/youtube";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function SocialMedia ( { type } : { type : string } ) {
    // render
    return (
        <>
            <Instagram type={type} />
            {/*<Facebook type={type} />*/}
            {/*<Twitter type={type} />*/}
            {/*<Youtube type={type} />*/}
        </>
    );
}